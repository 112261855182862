import "../styles/navbar.css";
import React, { useEffect, useState } from "react";

function NavBar() {
  const [activeSection, setActiveSection] = useState("home");

  const handleScroll = () => {
    const sections = ["home", "work", "contact"];
    let currentSection = "home";

    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element && window.scrollY >= element.offsetTop - 50) {
        currentSection = section;
      }
    });

    setActiveSection(currentSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light"       
    style={{
      width: '100%',
      margin: 0,
      padding: 0,
      position: 'fixed',
      top: 0,
      zIndex: 1000,
      backgroundColor: '#ffffff', // Adjust the color as needed
    }}>
      <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <a href="/" className="navbar-brand">
          meet d
        </a>
        <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul className="navbar-nav">
            <li className={`nav-item custom-tab ${activeSection === "home" ? "active" : ""}`}>
              <a href="" className="nav-link">
                <span className="tab-number"></span>// home
              </a>
            </li>
            <li className={`nav-item custom-tab ${activeSection === "work" ? "active" : ""}`}>
              <a href="#work" className="nav-link">
                <span className="tab-number"></span>// work
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}


export default NavBar;
