import React, { useEffect } from "react";
import "../styles/homepage.css";
import "../styles/about.css";
import Typist from "react-typist";
import About from "../components/Work";
import ThreeDAnimation from "../components/ThreeDAnimation";
import NavBar from "../components/NavBar";
import Game from "../components/Game";

const Home = () => {
  <img src={`${process.env.PUBLIC_URL}/assets/images/2.png`} alt="Logo" className="logo" />
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const element = document.getElementById(sectionId);
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
    <ThreeDAnimation />
      <section
        id="home"
        className="container-fluid vh-100 d-flex justify-content-center align-items-center position-relative"
      >
        <div
          className="col-12 text-center position-absolute top-50 translate-middle-y"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h1 className="display-4">Meet Digrajkar</h1>
          <Typist
            className="text-muted"
            cursor={{ show: true }}
            avgTypingDelay={40}
          >
            <h2 className="typist-title">Software Engineer</h2>
          </Typist>

          <div className="button-container lead">
            <a
              href="#about"
              className="btn btn-primary m-2"
              onClick={(e) => scrollToSection(e, "work")}
            >
              View My Work Experience.
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
