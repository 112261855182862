import React from "react";
import "../styles/about.css";
import "../styles/homepage.css";

const resumeUrl =
  "https://meetdigrajkarresume.s3.us-east-2.amazonaws.com/resume.pdf";

const Work = () => {
  const workExperience = [
    {
      company: "Embross",
      position: "Backend Software Engineer II",
      duration: "July 15, 2021 - Present",
      responsibilities: [
        "Implemented self-service device airline software transaction analytics via Metabase.",
        "Implemented self-service device transaction hardware analytics via data capture via client-server polling & data engineering.",
        "Improved security of remote device management software replacing database authentication with Auth0 authentication.",
        "Improved efficiency of the remote device management software & analytics software by implementing a caching layer.",
        "Deployed high-availability software systems to cloud infrastructure; AWS EC2s.",
        "Provided production support for systems delivery, infrastructure setup, and installation for device management software.",
        "Improved scheduler architecture for remote device management; for scheduling real-time commands to remote devices via polling.",
        "Integrated Email and Twilio SMS customized alerts/notifications for remote monitoring of devices.",
        "Produced high-level installation and deployment documentation of device management software for enterprise use.",
      ],
    },
    {
      company: "Tata Consultancy Services (TCS)",
      position: "Software Engineer I",
      duration: "May 10, 2021 - July 15, 2021",
      responsibilities: [
        "Implemented RESTful APIs using MuleSoft, RAML, and AnyPoint Platform.",
      ],
    },
  ];

  return (
    <section id="work" className="container my-5">
      <h2 className="text-center mb-4 lead" >My Work</h2>
      <div className="text-center mb-4">
        <a href={resumeUrl} download="resume.pdf" className="btn btn-primary lead">
          Download My Resume
        </a>
      </div>
      {workExperience.map((experience, index) => (
        <div key={index} className="mb-4" >
          <h4>{experience.position}</h4>
          <p className="text-muted lead">{experience.company}</p>
          <p>{experience.duration}</p>
          <ul>
            {experience.responsibilities.map((responsibility, i) => (
              <li key={i}>{responsibility}</li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  );
};

export default Work;
