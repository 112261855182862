import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const ThreeDAnimation = () => {
  const ref = useRef();

  useEffect(() => {
    const originalScale = {};
    const currentScale = {};
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x1a2a3a); // Soft navy blue background
    const mouse = new THREE.Vector2();
    const glowIntensity = {};  // To track the glow intensity of each cube

    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    ref.current.appendChild(renderer.domElement);
    
    // Create cubes with their own light source
    const createCubeWithLight = (x, y, z, id) => {
      const geometry = new THREE.BoxGeometry();
      const material = new THREE.MeshStandardMaterial({ color: 0x575757, metalness: 0.5, roughness: 0.5 });
      const cube = new THREE.Mesh(geometry, material);
      cube.position.set(x, y, z);
      const light = new THREE.PointLight(0xffffff, 0.5, 50);
      light.position.set(x, y, z);
      originalScale[id] = 1;  // Initialize the original scale for the cube
      currentScale[id] = 1;  // Initialize the current scale for the cube
      cube.uuid = id;  // Set custom UUID for cube
      light.uuid = id + "Light";  // Set custom UUID for light
      glowIntensity[id] = 0;  // Initialize glow intensity for cube
      glowIntensity[id + "Light"] = 0;  // Initialize glow intensity for light
      scene.add(cube, light);
      return { cube, light };
    };
    const original = createCubeWithLight(-1.7, 0, 1, 'original');
    const bottomRight = createCubeWithLight(2.5, -0.5, -0.5, 'bottomRight');
    const topRight = createCubeWithLight(1, 0.8, -3, 'topRight');
    const cubes = [original.cube, bottomRight.cube, topRight.cube];

    // Step 1.1: Define Particle Geometry
    const particleGeometry = new THREE.BufferGeometry();
    const particles = 1000;
    const positions = new Float32Array(particles * 3);
    for (let i = 0; i < positions.length; i++) {
    positions[i] = (Math.random() - 0.5) * 20; // Random positions within a range
    }
    particleGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

    // Step 1.2: Define Particle Material
    const particleMaterial = new THREE.PointsMaterial({
    color: 0xffffff,
    size: 0.1,
    transparent: true,
    opacity: 0.5
    });

    // Step 1.3: Create Particle System
    const particleSystem = new THREE.Points(particleGeometry, particleMaterial);
    scene.add(particleSystem);

    // ...
    camera.position.z = 3;

    // Add ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // White color, 50% intensity
    scene.add(ambientLight);

    // Add directional light
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(1, 1, 1);
    scene.add(directionalLight);

    // Create a raycaster and array of cubes
    const raycaster = new THREE.Raycaster();

    // Mapping cube UUIDs to section IDs
    const cubeToSectionMapping = {
      'original': 'home',
      'bottomRight': 'work',
      'topRight': 'contact'
    };

    // Event listener for mouse hover
    const onMouseMove = (event) => {
      event.preventDefault();

      // Calculate mouse position
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      // Update raycaster
      raycaster.setFromCamera(mouse, camera);

      // Check for intersections
      const intersects = raycaster.intersectObjects(cubes);

      // Set target scales for intersected cubes
      intersects.forEach(({ object }) => {
        const id = object.uuid;
        glowIntensity[id] = Math.min(1, glowIntensity[id] + 0.1);
        currentScale[id] = 1.5;  // Set the target scale to 1.5x
      });

      // In the absence of intersection, set the target scale back to 1
      Object.keys(currentScale).forEach(id => {
        if (!intersects.some(({ object }) => object.uuid === id)) {
          currentScale[id] = 1;  // Set the target scale back to 1
        }
      });

      // Update light intensities based on glowIntensity
      [original, bottomRight, topRight].forEach(({ cube, light }) => {
        const cubeId = cube.uuid;
        const lightId = light.uuid;
        light.intensity = glowIntensity[lightId];  // Update the light intensity based on the light's glowIntensity
      });
    };

    window.addEventListener('mousemove', onMouseMove, false);

    // Animation loop
    const animate = () => {
    requestAnimationFrame(animate);
  
    // Slight left and right movement for the sphere
    const time = Date.now() * 0.0005; // Control the speed of the movement
    const movementAmplitude = 0.1; // Control the range of the movement
    // Step 2: Animate the Particles
    particleSystem.rotation.x += 0.001;
    particleSystem.rotation.y += 0.001;
    // Add rotation to the cubes
    original.cube.rotation.x += 0.005;
    original.cube.rotation.y += 0.005;
    bottomRight.cube.rotation.x += 0.005;
    bottomRight.cube.rotation.y += 0.005;
    topRight.cube.rotation.x += 0.005;
    topRight.cube.rotation.y += 0.005;

    // Smoothly transition the scale of each cube
    Object.keys(currentScale).forEach(id => {
      const cube = scene.getObjectByProperty('uuid', id);
      const targetScale = currentScale[id];
      const newScale = cube.scale.x + (targetScale - cube.scale.x) * 0.1;  // Linear interpolation
      cube.scale.set(newScale, newScale, newScale);
    });

    renderer.render(scene, camera);
  };

    animate();

    // Handle window resize
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', onMouseMove); // Clean up event listener
    };
  }, []);


  return <div ref={ref} className="code-animation-container" style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }} />;
};

export default ThreeDAnimation;
