import React from "react";
import Home from "./pages/Home";
import Work from "./components/Work";
import Contact from "./components/Contact";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Game from "./components/Game";
import SalesExport from "./components/SalesExport"; // Make sure the path matches your file structure

function App() {
  return (
    <div className="App">
      <NavBar />
      <Home id="home"/>
      <Work id="work"/>
      <SalesExport id="salesExport"/>
      <Footer id="footer" />
    </div>
  );
}

export default App;
