import React, { useState, useEffect, useCallback } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { gapi } from 'gapi-script';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { debounce } from 'lodash';

Chart.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];
const SCOPES = "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/spreadsheets";

function SalesExport() {
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({ frontSales: 0, callCenterSales: 0, uberSkipSales: 0, totalSales: 0 });
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [showTool, setShowTool] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [spreadsheetId, setSpreadsheetId] = useState(null);
    const [gapiInitialized, setGapiInitialized] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: '', type: '' });
    const [showAnalytics, setShowAnalytics] = useState(false);
    const [isAddRowDisabled, setIsAddRowDisabled] = useState(false);
    const [cachedData, setCachedData] = useState({});

    // New state variables for date range selection and analytics data
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [analyticsData, setAnalyticsData] = useState([]);
    const [analyticsTotals, setAnalyticsTotals] = useState({ frontSales: 0, callCenterSales: 0, uberSkipSales: 0, totalSales: 0 });

    // New state variables for uploaded spreadsheet
    const [salesSpreadsheetId, setSalesSpreadsheetId] = useState(null);
    const [uploadedSpreadsheetId, setUploadedSpreadsheetId] = useState(null);
    const [useUploadedSpreadsheet, setUseUploadedSpreadsheet] = useState(false);

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    useEffect(() => {
        calculateTotals();
    }, [data]);

    const checkOrCreateSpreadsheet = useCallback(() => {
        if (!gapiInitialized) {
            console.error("gapi client not initialized");
            return;
        }

        gapi.client.drive.files.list({
            q: "mimeType='application/vnd.google-apps.spreadsheet' and name='Sales'",
            fields: 'files(id, name)',
            spaces: 'drive'
        }).then(response => {
            const files = response.result.files;
            if (files && files.length > 0) {
                setSalesSpreadsheetId(files[0].id);
                if (!useUploadedSpreadsheet) {
                    setSpreadsheetId(files[0].id);
                }
                console.log('Using existing spreadsheet. ID: ' + files[0].id);
            } else {
                console.error("Spreadsheet 'Sales' not found.");
            }
        }).catch(error => {
            console.error("Error checking for existing spreadsheet: ", error);
        });
    }, [gapiInitialized, useUploadedSpreadsheet]);

    const checkOrCreateSheet = useCallback(async (month, year) => {
        const sheetTitle = `${month} ${year}`;
        try {
            const response = await gapi.client.sheets.spreadsheets.get({
                spreadsheetId: spreadsheetId
            });
            const sheets = response.result.sheets.map(sheet => sheet.properties.title);
            if (!sheets.includes(sheetTitle)) {
                await gapi.client.sheets.spreadsheets.batchUpdate({
                    spreadsheetId: spreadsheetId,
                    resource: {
                        requests: [{
                            addSheet: {
                                properties: {
                                    title: sheetTitle
                                }
                            }
                        }]
                    }
                });
            }
        } catch (error) {
            console.error("Error checking or creating sheet: ", error);
        }
    }, [spreadsheetId]);

    const loadDataFromSheet = useCallback(async (month, year) => {
        if (!spreadsheetId) {
            console.error("Spreadsheet ID is not set.");
            return;
        }
        const cacheKey = `${month}-${year}`;
        if (cachedData[cacheKey]) {
            setData(cachedData[cacheKey]);
            updateAddRowButtonState(cachedData[cacheKey], month, year);
            return;
        }

        const sheetTitle = `${month} ${year}`;
        try {
            const response = await gapi.client.sheets.spreadsheets.values.get({
                spreadsheetId: spreadsheetId,
                range: `${sheetTitle}!A:E`
            });
            const values = response.result.values || [];
            const loadedData = values.slice(1).map(row => ({
                date: row[0] === 'Totals' ? 'Totals' : formatDate(row[0]),
                frontSales: parseFloat(row[1].replace(/[^0-9.-]+/g, "")) || 0,
                callCenterSales: parseFloat(row[2].replace(/[^0-9.-]+/g, "")) || 0,
                uberSkipSales: parseFloat(row[3].replace(/[^0-9.-]+/g, "")) || 0,
                totalSales: parseFloat(row[4].replace(/[^0-9.-]+/g, "")) || 0
            }));
            setData(loadedData);
            setCachedData(prev => ({ ...prev, [cacheKey]: loadedData }));
            updateAddRowButtonState(loadedData, month, year);
        } catch (error) {
            console.error("Error loading data from sheet: ", error);
        }
    }, [spreadsheetId, cachedData]);

    const updateAddRowButtonState = (data, month, year) => {
        const monthIndex = months.indexOf(month);
        const daysInMonth = getDaysInMonth(monthIndex + 1, year);
        if (data.length >= daysInMonth) {
            setIsAddRowDisabled(true);
        } else {
            setIsAddRowDisabled(false);
        }
    };

    const calculateAndAppendTotals = (data) => {
        const filteredData = data.filter(row => row.date !== 'Totals');

        const updatedData = filteredData.map(row => ({
            ...row,
            totalSales: row.frontSales + row.callCenterSales + row.uberSkipSales
        }));

        const totals = updatedData.reduce((acc, row) => ({
            frontSales: acc.frontSales + row.frontSales,
            callCenterSales: acc.callCenterSales + row.callCenterSales,
            uberSkipSales: acc.uberSkipSales + row.uberSkipSales,
            totalSales: acc.totalSales + row.totalSales
        }), { frontSales: 0, callCenterSales: 0, uberSkipSales: 0, totalSales: 0 });

        updatedData.push({
            date: 'Totals',
            frontSales: totals.frontSales,
            callCenterSales: totals.callCenterSales,
            uberSkipSales: totals.uberSkipSales,
            totalSales: totals.totalSales
        });

        return updatedData;
    };

    const prepareDataForSaving = useCallback((data) => {
        const cleanData = data.filter(row => row.date !== 'Totals');
        return calculateAndAppendTotals(cleanData);
    }, []);

    const saveDataToSheet = useCallback(async (month, year) => {
        if (!spreadsheetId) {
            setNotification({ show: true, type: 'danger', message: 'Spreadsheet ID is not set.' });
            return;
        }
        const sheetTitle = `${month} ${year}`;
        let formattedData = prepareDataForSaving(data);

        const values = [
            ["Date", "Front Sales", "Call Center Sales", "Uber And Skip Sales", "Total Sales"],
            ...formattedData.map(row => [
                row.date === 'Totals' ? 'Totals' : formatDate(row.date, true),
                `$ ${row.frontSales.toFixed(2)}`,
                `$ ${row.callCenterSales.toFixed(2)}`,
                `$ ${row.uberSkipSales.toFixed(2)}`,
                `$ ${row.totalSales.toFixed(2)}`
            ])
        ];

        try {
            await gapi.client.sheets.spreadsheets.values.update({
                spreadsheetId: spreadsheetId,
                range: `${sheetTitle}!A:E`,
                valueInputOption: 'RAW',
                resource: { values }
            });
            setNotification({ show: true, type: 'success', message: 'Data saved successfully!' });

            const updatedData = formattedData.map(row => ({
                date: row.date,
                frontSales: parseFloat(row.frontSales.toString().replace(/[^0-9.-]+/g, "")) || 0,
                callCenterSales: parseFloat(row.callCenterSales.toString().replace(/[^0-9.-]+/g, "")) || 0,
                uberSkipSales: parseFloat(row.uberSkipSales.toString().replace(/[^0-9.-]+/g, "")) || 0,
                totalSales: parseFloat(row.totalSales.toString().replace(/[^0-9.-]+/g, "")) || 0
            }));
            setData(updatedData);
        } catch (error) {
            console.error("Error saving data to sheet: ", error);
            setNotification({ show: true, type: 'danger', message: 'Failed to save data.' });
        }
    }, [data, spreadsheetId, prepareDataForSaving]);

    const handleMonthYearChange = useCallback(debounce(() => {
        if (!spreadsheetId) return;

        checkOrCreateSheet(selectedMonth, selectedYear).then(() => {
            loadDataFromSheet(selectedMonth, selectedYear);
        });
    }, 300), [checkOrCreateSheet, loadDataFromSheet, selectedMonth, selectedYear, spreadsheetId]);

    const getDaysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    };

    const addRow = () => {
        if (!selectedMonth || !selectedYear) {
            setNotification({ show: true, type: 'danger', message: 'Please select a month and year first.' });
            return;
        }

        const monthIndex = months.indexOf(selectedMonth);
        const daysInMonth = getDaysInMonth(monthIndex + 1, selectedYear);

        if (data.length >= daysInMonth) {
            setIsAddRowDisabled(true);
            setNotification({ show: true, type: 'danger', message: 'Maximum days in month reached.' });
            return;
        }

        let newDate;
        if (data.length > 0) {
            const lastDateIndex = data[data.length - 1].date === 'Totals' ? data.length - 2 : data.length - 1;
            const lastDate = new Date(data[lastDateIndex].date);
            newDate = new Date(lastDate);
            newDate.setDate(newDate.getDate() + 1);
        } else {
            newDate = new Date(selectedYear, monthIndex, 1);
        }

        if (isNaN(newDate)) {
            setNotification({ show: true, type: 'danger', message: 'Invalid date value. Please check the last date entry.' });
            return;
        }

        const newData = [...data];
        if (newData.length > 0 && newData[newData.length - 1].date === 'Totals') {
            newData.splice(newData.length - 1, 0, {
                date: newDate.toISOString().split('T')[0],
                frontSales: 0,
                callCenterSales: 0,
                uberSkipSales: 0,
                totalSales: 0
            });
        } else {
            newData.push({
                date: newDate.toISOString().split('T')[0],
                frontSales: 0,
                callCenterSales: 0,
                uberSkipSales: 0,
                totalSales: 0
            });
        }
        setData(newData);
    };

    const handleChange = (index, field, value) => {
        const newData = [...data];
        newData[index] = { ...newData[index], [field]: parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0 };
        newData[index].totalSales = newData[index].frontSales + newData[index].callCenterSales + newData[index].uberSkipSales;
        setData(newData);
    };

    useEffect(() => {
        function initClient() {
            gapi.load('client:auth2', () => {
                gapi.client.init({
                    clientId: CLIENT_ID,
                    discoveryDocs: DISCOVERY_DOCS,
                    scope: SCOPES,
                }).then(() => {
                    return gapi.client.load('drive', 'v3');
                }).then(() => {
                    setGapiInitialized(true);
                    gapi.auth2.getAuthInstance().isSignedIn.listen(setIsSignedIn);
                    setIsSignedIn(gapi.auth2.getAuthInstance().isSignedIn.get());
                    if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
                        checkOrCreateSpreadsheet();
                    }
                }).catch(error => {
                    console.error("Error initializing gapi client: ", error);
                });
            });
        }

        initClient();
    }, [checkOrCreateSpreadsheet]);

    useEffect(() => {
        handleMonthYearChange();
    }, [handleMonthYearChange]);

    const handleSignInClick = () => {
        gapi.auth2.getAuthInstance().signIn().then(() => {
            checkOrCreateSpreadsheet();
        });
    };

    const handleSignOutClick = () => {
        gapi.auth2.getAuthInstance().signOut();
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const adjustColumnWidths = async (spreadsheetId, sheetId) => {
        const requests = [
            {
                updateDimensionProperties: {
                    range: {
                        sheetId: sheetId,
                        dimension: 'COLUMNS',
                        startIndex: 0,
                        endIndex: 5
                    },
                    properties: {
                        pixelSize: 300
                    },
                    fields: 'pixelSize'
                }
            }
        ];

        try {
            await gapi.client.sheets.spreadsheets.batchUpdate({
                spreadsheetId: spreadsheetId,
                resource: {
                    requests: requests
                }
            });
        } catch (error) {
            console.error("Error adjusting column widths: ", error);
        }
    };

    const exportToPdf = async () => {
        if (!spreadsheetId) {
            setNotification({ show: true, type: 'danger', message: 'Spreadsheet ID is not set.' });
            return;
        }
        const sheetTitle = `${selectedMonth} ${selectedYear}`;

        try {
            const details = await gapi.client.sheets.spreadsheets.get({
                spreadsheetId: spreadsheetId
            });

            const sheet = details.result.sheets.find(sheet => sheet.properties.title === sheetTitle);

            if (!sheet) {
                console.error("Sheet not found.");
                return;
            }

            const sheetId = sheet.properties.sheetId;

            await adjustColumnWidths(spreadsheetId, sheetId);

            const accessToken = gapi.auth.getToken().access_token;
            const exportUrl = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/export?exportFormat=pdf&format=pdf` +
                `&size=A4&portrait=true&fitw=true&sheetnames=true&printtitle=true&pagenumbers=true&gridlines=true` +
                `&fzr=false&gid=${sheetId}` +
                `&range=${sheetTitle}!A:E` +
                `&top_margin=0.5` +
                `&bottom_margin=0.5` +
                `&left_margin=0.5` +
                `&right_margin=0.5` +
                `&horizontal_alignment=CENTER` +
                `&vertical_alignment=TOP` +
                `&scale=4` +
                `&fitw=true` +
                `&fh=true`;

            const response = await fetch(exportUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf'
                }
            });

            if (response.ok) {
                const blob = await response.blob();
                setNotification({ show: true, type: 'success', message: 'PDF exported successfully!' });
                saveAs(blob, `${sheetTitle}.pdf`);
            } else {
                console.error('Failed to download PDF:', response);
            }
        } catch (error) {
            console.error("Error exporting to PDF: ", error);
            setNotification({ show: true, type: 'danger', message: 'Failed to export PDF.' });
        }
    };

    const calculateTotals = () => {
        const cleanData = data.filter(row => row.date !== 'Totals');
        const newTotals = cleanData.reduce((acc, curr) => ({
            frontSales: acc.frontSales + curr.frontSales,
            callCenterSales: acc.callCenterSales + curr.callCenterSales,
            uberSkipSales: acc.uberSkipSales + curr.uberSkipSales,
            totalSales: acc.totalSales + curr.totalSales
        }), { frontSales: 0, callCenterSales: 0, uberSkipSales: 0, totalSales: 0 });
        setTotals(newTotals);
    };

    const getPieChartData = () => {
        return {
            labels: ['Front Sales', 'Call Center Sales', 'Uber And Skip Sales'],
            datasets: [
                {
                    data: [analyticsTotals.frontSales, analyticsTotals.callCenterSales, analyticsTotals.uberSkipSales],
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
                }
            ]
        };
    };

    const displayTotals = () => {
        return (
            <div className="totals-display">
                <h4>Totals:</h4>
                <p>Front Sales: ${totals.frontSales.toFixed(2)}</p>
                <p>Call Center Sales: ${totals.callCenterSales.toFixed(2)}</p>
                <p>Uber And Skip Sales: ${totals.uberSkipSales.toFixed(2)}</p>
                <p>Total Sales: ${totals.totalSales.toFixed(2)}</p>
            </div>
        );
    };

    const formatDate = (dateString, reverse = false) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date)) {
            return dateString;
        }
        if (reverse) {
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        } else {
            const [month, day, year] = dateString.split('/');
            return `${year}-${month}-${day}`;
        }
    };

    // New functions to handle loading and processing analytics data
    const handleLoadAnalyticsData = () => {
        if (selectedStartDate && selectedEndDate) {
            loadDataForDateRange(selectedStartDate, selectedEndDate);
        } else {
            setNotification({ show: true, type: 'danger', message: 'Please select both start date and end date.' });
        }
    };

    const loadDataForDateRange = async (startDate, endDate) => {
        if (!startDate || !endDate) {
            console.error("Start date or end date is not defined");
            return;
        }
        if (!spreadsheetId) {
            setNotification({ show: true, type: 'danger', message: 'Spreadsheet ID is not set.' });
            return;
        }

        try {
            const response = await gapi.client.sheets.spreadsheets.get({
                spreadsheetId: spreadsheetId
            });

            const sheets = response.result.sheets;
            let collectedData = [];

            for (const sheet of sheets) {
                const sheetTitle = sheet.properties.title;
                const [monthName, yearStr] = sheetTitle.split(' ');
                const monthIndex = months.indexOf(monthName);
                const year = parseInt(yearStr);

                if (monthIndex === -1 || isNaN(year)) {
                    continue;
                }

                const sheetStartDate = new Date(year, monthIndex, 1);
                const sheetEndDate = new Date(year, monthIndex + 1, 0);

                if (sheetEndDate >= new Date(startDate) && sheetStartDate <= new Date(endDate)) {
                    const sheetData = await loadDataFromSheetForAnalytics(sheetTitle);
                    collectedData = collectedData.concat(sheetData);
                }
            }

            setAnalyticsData(collectedData);
            calculateTotalsForAnalyticsData(collectedData);

        } catch (error) {
            console.error("Error loading data for date range: ", error);
        }
    };

    const loadDataFromSheetForAnalytics = async (sheetTitle) => {
        try {
            const response = await gapi.client.sheets.spreadsheets.values.get({
                spreadsheetId: spreadsheetId,
                range: `${sheetTitle}!A:E`
            });
            const values = response.result.values || [];
            const loadedData = values.slice(1).map(row => ({
                date: row[0] === 'Totals' ? 'Totals' : formatDate(row[0]),
                frontSales: parseFloat(row[1].replace(/[^0-9.-]+/g, "")) || 0,
                callCenterSales: parseFloat(row[2].replace(/[^0-9.-]+/g, "")) || 0,
                uberSkipSales: parseFloat(row[3].replace(/[^0-9.-]+/g, "")) || 0,
                totalSales: parseFloat(row[4].replace(/[^0-9.-]+/g, "")) || 0
            }));
            return loadedData;
        } catch (error) {
            console.error("Error loading data from sheet: ", error);
            return [];
        }
    };

    const calculateTotalsForAnalyticsData = (data) => {
        const filteredData = data.filter(row => row.date !== 'Totals' && row.date !== '');
        const startDate = new Date(selectedStartDate);
        const endDate = new Date(selectedEndDate);

        const dataInRange = filteredData.filter(row => {
            const rowDate = new Date(row.date);
            return rowDate >= startDate && rowDate <= endDate;
        });

        // Update analyticsData to only include data within the date range
        setAnalyticsData(dataInRange);

        const newTotals = dataInRange.reduce((acc, curr) => ({
            frontSales: acc.frontSales + curr.frontSales,
            callCenterSales: acc.callCenterSales + curr.callCenterSales,
            uberSkipSales: acc.uberSkipSales + curr.uberSkipSales,
            totalSales: acc.totalSales + curr.totalSales
        }), { frontSales: 0, callCenterSales: 0, uberSkipSales: 0, totalSales: 0 });

        setAnalyticsTotals(newTotals);
    };

    const getMonthlySalesData = () => {
        const dataByMonth = {};

        for (const row of analyticsData) {
            if (row.date !== 'Totals' && row.date !== '') {
                const rowDate = new Date(row.date);
                const monthKey = `${rowDate.getFullYear()}-${(rowDate.getMonth() + 1).toString().padStart(2, '0')}`;
                if (!dataByMonth[monthKey]) {
                    dataByMonth[monthKey] = {
                        frontSales: 0,
                        callCenterSales: 0,
                        uberSkipSales: 0,
                        totalSales: 0
                    };
                }
                dataByMonth[monthKey].frontSales += row.frontSales;
                dataByMonth[monthKey].callCenterSales += row.callCenterSales;
                dataByMonth[monthKey].uberSkipSales += row.uberSkipSales;
                dataByMonth[monthKey].totalSales += row.totalSales;
            }
        }

        return Object.keys(dataByMonth).map(monthKey => ({
            month: monthKey,
            ...dataByMonth[monthKey]
        })).sort((a, b) => new Date(a.month) - new Date(b.month));
    };

    const getBarChartData = () => {
        const monthlySalesData = getMonthlySalesData();

        return {
            labels: monthlySalesData.map(monthData => monthData.month),
            datasets: [
                {
                    label: 'Front Sales',
                    backgroundColor: '#FF6384',
                    data: monthlySalesData.map(monthData => monthData.frontSales),
                },
                {
                    label: 'Call Center Sales',
                    backgroundColor: '#36A2EB',
                    data: monthlySalesData.map(monthData => monthData.callCenterSales),
                },
                {
                    label: 'Uber And Skip Sales',
                    backgroundColor: '#FFCE56',
                    data: monthlySalesData.map(monthData => monthData.uberSkipSales),
                },
                {
                    label: 'Total Sales',
                    backgroundColor: '#4BC0C0',
                    data: monthlySalesData.map(monthData => monthData.totalSales),
                }
            ]
        };
    };

    const barChartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Monthly Sales'
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Months'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Sales Amount'
                }
            }
        }
    };

    // Handle file upload and conversion to Google Sheets
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const fileName = file.name;

        // Read the file as array buffer
        const reader = new FileReader();
        reader.onload = async (event) => {
            const content = event.target.result;

            try {
                // Upload the file to Google Drive and convert it to Google Sheets
                const accessToken = gapi.auth.getToken().access_token;

                const metadata = {
                    name: fileName,
                    mimeType: 'application/vnd.google-apps.spreadsheet'
                };

                const form = new FormData();
                form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
                form.append('file', new Blob([content], { type: file.type }));

                const response = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id', {
                    method: 'POST',
                    headers: new Headers({ 'Authorization': 'Bearer ' + accessToken }),
                    body: form,
                });

                if (response.ok) {
                    const result = await response.json();
                    const newSpreadsheetId = result.id;
                    setUploadedSpreadsheetId(newSpreadsheetId);
                    setUseUploadedSpreadsheet(true);
                    setSpreadsheetId(newSpreadsheetId);

                    setNotification({ show: true, type: 'success', message: 'File uploaded and converted to Google Sheets successfully!' });
                } else {
                    console.error('Error uploading file:', response.statusText);
                    setNotification({ show: true, type: 'danger', message: 'Failed to upload and convert file.' });
                }
            } catch (error) {
                console.error('Error uploading file:', error);
                setNotification({ show: true, type: 'danger', message: 'Failed to upload and convert file.' });
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const handleSpreadsheetSelection = (e) => {
        const value = e.target.value;
        if (value === 'uploaded') {
            setUseUploadedSpreadsheet(true);
            setSpreadsheetId(uploadedSpreadsheetId);
        } else if (value === 'sales') {
            setUseUploadedSpreadsheet(false);
            setSpreadsheetId(salesSpreadsheetId);
        }
    };

    // Render the component
    return (
        <div className="container mt-5">
            {notification.show && (
                <div className={`alert alert-${notification.type} alert-dismissible fade show`} role="alert">
                    {notification.message}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setNotification({ ...notification, show: false })}></button>
                </div>
            )}
            <button className="btn btn-outline-primary mb-3" onClick={() => setShowTool(!showTool)}>
                {showTool ? 'Hide' : 'Open Simplify'}
            </button>

            {showTool && (
                <div style={{ backgroundColor: 'rgba(211, 211, 211, 0.5)', padding: '20px', marginBottom: '50px' }}>
                    {!isSignedIn ? (
                        <button className="btn btn-primary" onClick={handleSignInClick}>Sign In to Google</button>
                    ) : (
                        <>
                            <button className="btn btn-outline-dark" onClick={handleSignOutClick}>Sign Out of Google</button>
                            <h1 className="text-center mb-4 app-title">Simplify</h1>

                            {/* File upload section */}
                            <div className="mb-3">
                                <label htmlFor="fileUpload" className="form-label">Upload Excel File:</label>
                                <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} className="form-control" id="fileUpload" />
                            </div>

                            {/* Spreadsheet selection */}
                            <div className="mb-3">
                                <label htmlFor="spreadsheetSelect" className="form-label">Select Spreadsheet:</label>
                                <select className="form-select" value={useUploadedSpreadsheet ? 'uploaded' : 'sales'} onChange={handleSpreadsheetSelection} id="spreadsheetSelect">
                                    <option value="sales">Use Existing 'Sales' Spreadsheet</option>
                                    {uploadedSpreadsheetId && <option value="uploaded">Use Uploaded Spreadsheet</option>}
                                </select>
                            </div>

                            <div className="mb-3">
                                <select className="form-select" value={selectedMonth} onChange={handleMonthChange}>
                                    <option value="">Select Month</option>
                                    {months.map(month => (
                                        <option key={month} value={month}>{month}</option>
                                    ))}
                                </select>
                                <select className="form-select" value={selectedYear} onChange={handleYearChange}>
                                    {Array.from(new Array(10), (val, index) => {
                                        const year = new Date().getFullYear() - index;
                                        return <option key={year} value={year}>{year}</option>;
                                    })}
                                </select>
                            </div>
                            <button className="btn btn-primary mb-3" disabled={isAddRowDisabled} onClick={addRow}>Add Row</button>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="table-title mb-4">Date</th>
                                            <th className="table-title mb-4">Front Sales</th>
                                            <th className="table-title mb-4">Call Center Sales</th>
                                            <th className="table-title mb-4">Uber And Skip Sales</th>
                                            <th className="table-title mb-4">Total Sales</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.date}</td>
                                                <td>
                                                    <input className="form-control" type="number" value={row.frontSales}
                                                        onChange={e => handleChange(index, 'frontSales', e.target.value)}
                                                        disabled={row.date === 'Totals'} />
                                                </td>
                                                <td>
                                                    <input className="form-control" type="number" value={row.callCenterSales}
                                                        onChange={e => handleChange(index, 'callCenterSales', e.target.value)}
                                                        disabled={row.date === 'Totals'} />
                                                </td>
                                                <td>
                                                    <input className="form-control" type="number" value={row.uberSkipSales}
                                                        onChange={e => handleChange(index, 'uberSkipSales', e.target.value)}
                                                        disabled={row.date === 'Totals'} />
                                                </td>
                                                <td>
                                                    ${row.totalSales.toFixed(2)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <button className="btn btn-success me-2" onClick={exportToPdf}>Export</button>
                            <button className="btn btn-primary me-2" onClick={() => saveDataToSheet(selectedMonth, selectedYear)}>Save to Google Sheets</button>
                            <button className="btn btn-outline-dark me-2" onClick={() => setShowAnalytics(!showAnalytics)}>
                                {showAnalytics ? 'Hide Analytics' : 'Show Analytics'}
                            </button>
                            {showAnalytics && (
                                <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                                    <div className="date-range-selection mb-3">
                                        <label className="me-2">
                                            Start Date:
                                            <input type="date" value={selectedStartDate} onChange={(e) => setSelectedStartDate(e.target.value)} />
                                        </label>
                                        <label className="me-2">
                                            End Date:
                                            <input type="date" value={selectedEndDate} onChange={(e) => setSelectedEndDate(e.target.value)} />
                                        </label>
                                        <button className="btn btn-primary" onClick={handleLoadAnalyticsData}>Load Data</button>
                                    </div>
                                    {analyticsTotals.totalSales > 0 && (
                                        <div className="totals-display">
                                            <h4>Totals for Selected Date Range:</h4>
                                            <p>Front Sales: ${analyticsTotals.frontSales.toFixed(2)}</p>
                                            <p>Call Center Sales: ${analyticsTotals.callCenterSales.toFixed(2)}</p>
                                            <p>Uber And Skip Sales: ${analyticsTotals.uberSkipSales.toFixed(2)}</p>
                                            <p>Total Sales: ${analyticsTotals.totalSales.toFixed(2)}</p>
                                        </div>
                                    )}
                                    {analyticsData.length > 0 && (
                                        <>
                                            <Pie data={getPieChartData()} />
                                            <div style={{ position: 'relative', height: '400px', marginTop: '30px' }}>
                                                <Bar data={getBarChartData()} options={barChartOptions} />
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default SalesExport;
